<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/phones" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Telefon Güncelle</h3>
        <a
          @click="removeItem()"
          type="button"
          style="position: absolute; right: 25px"
        >
          <i class="dropdown-icon si si-trash"></i>
        </a>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Başlık</label>
              <input
                type="text"
                name="title"
                :value="phone.phone_title"
                class="form-control"
                placeholder="Başlık"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Telefon Numarası</label>
              <input
                type="text"
                class="form-control"
                name="phone"
                v-model="phonen"
                v-mask="'0(###) ###-##-##'"
                placeholder="0(###) ###-##-##"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  created() {
    this.$store
      .dispatch("phoneGet", { id: this.$route.params.id })
      .then((value) => {
        this.phone = value[0];
        document.title = value[0].phone_title;
        this.phonen = value[0].phone;
      });
  },
  data() {
    return {
      phone: [],
      phonen: "",
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
    };
  },
  methods: {
    removeItem() {
      Swal.fire({
        title: "Bu telefonu silmek istediğine emin misin?",
        text: "Bu işlem sonrası seçilen telefon numarası silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let phoneSave = {
            id: this.phone.id,
          };
          this.$store.dispatch("phoneRemove", phoneSave).then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
            } else {
              this.$vToastify.success(
                "Telefon bilgileri başarılı bir şekilde silindi",
                "Başarılı!"
              );
              this.$router.push("/profile/phones");
            }
          });
        }
      });
    },
    save() {
      let title = document.querySelector("input[name=title]").value;
      let number = document.querySelector("input[name=phone]").value;
      if (title == null || title == "") {
        this.$vToastify.warning("Telefon başlığı girilmedi", "Uyarı!");
      } else if (number == null || number == "") {
        this.$vToastify.warning("Telefon numarası girilmedi", "Uyarı!");
      } else if (!number.match(this.phoneRegex)) {
        this.$vToastify.warning("Hatalı telefon numarası", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let phoneSave = {
          id: this.$route.params.id,
          title: title,
          number: number,
        };
        this.$store.dispatch("phoneUpdate", phoneSave).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          if (value.type == "error") {
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
          } else {
            this.$vToastify.success(
              "Telefon bilgileri başarılı bir şekilde güncellendi",
              "Başarılı!"
            );
            this.$router.push("/profile/phones");
          }
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>